<template>
  <div
    v-if="
      $store.state.fastPermissoes.administrador == 'S' &&
      $store.state.fastPermissoes.ativo == 'S'
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="
                  $router.push('/plataforma/' + $route.params.id_plataforma)
                "
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="
                  $router.push(
                    '/plataforma/' + $route.params.id_plataforma + '/admin'
                  )
                "
                >Modo admin</a
              >
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a class="aluno_font_color" href="#">Emails</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Emails da
                  <span>Plataforma</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')" />
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <h3>FastEAD</h3>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>

                        <th>
                          <small class="font-weight-bold">Nome</small>
                        </th>
                        <th>
                          <small class="font-weight-bold">Assunto</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ativo</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ações</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastPlataformaEmail.length">
                      <tr v-for="e in fastPlataformaEmail" :key="e.id_email_template">
                        <td class="align-middle">
                          <a href="#" @click.prevent="exibeModalEditarEmail(e)">
                            <small>{{ e.nome_email }}</small>
                          </a>
                        </td>
                        <td class="align-middle">
                          <a href="#" @click.prevent="exibeModalEditarEmail(e)">
                            <small>{{ e.fastEmailTemplatePlataforma.assunto }}</small>
                          </a>
                        </td>
                        <td class="text-center align-middle">
                          <small
                            v-if="!e.fastEmailTemplatePlataforma.ativo"
                            class="btn btn-sm btn-danger pt-0 pb-0 text-lowercase"
                            >Não</small
                          >
                          <small
                            v-else
                            class="btn btn-sm btn-success pt-0 pb-0 text-lowercase"
                            >Sim</small
                          >
                        </td>
                        <td class="text-center align-middle">
                          <button
                            class="btn btn-sm btn-warning text-white pt-0 pb-0"
                            @click="exibeModalRestaurarEmail(e.id_email_template, e.fastEmailTemplatePlataforma.email_envio, e.fastEmailTemplatePlataforma.email_copia, e.assunto_padrao, e.html_email_padrao)"
                          >
                            <small>Restaurar padrão</small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/admin'
                  "
                  @click.prevent="
                    $router.push(
                      '/plataforma/' + $route.params.id_plataforma + '/admin'
                    )
                  "
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalEditarEmail"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmail')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastPlataformaEmailEditar.nome_email"
                class="form-control"
                type="text"
                readonly
              />
            </div>

            <div
              v-if="fastPlataformaEmailEditar.destino_fixo"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold"
                >Email para envio</label
              >
              <input
                v-model="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.email_envio"
                class="form-control"
                type="text"
              />
            </div>
            <div
              v-if="fastPlataformaEmailEditar.destino_fixo"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.email_copia"
                class="form-control"
                type="text"
              />
            </div>
            <div
              v-if="!fastPlataformaEmailEditar.sempre_ativo"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item shadow-none border-0 p-0"
                  >
                    <label class="switch mr-2">
                      <input
                        v-model="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.ativo"
                        :checked="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.ativo"
                        type="checkbox"
                      />
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Assunto</label>
              <input
                v-model="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.assunto"
                class="form-control"
                type="text"
              />
            </div>
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastPlataformaEmailEditar.descricao_email }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small class="m-0 font-weight-bold text-secondary"
                  >Você pode usar as seguintes palavras especiais no seu
                  texto:</small
                >
                <table>
                  <tbody>
                    <tr>
                      <td
                        v-for="v in this.fastPlataformaEmailEditar.fastEmailTemplateVariavel"  :key="v.id_email_template_variavel">
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ v.nome_variavel }} </span> &nbsp;&nbsp;&nbsp;
                        </small>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info"
                  >Corpo do email que será enviado, utilize o editor para
                  inserir links, imagens e customizar o texto. Ou personalize o
                  HTML do corpo do email clicando aqui:
                  <button
                    class="btn btn-sm btn-primary pt-0 pb-0"
                    @click="
                      showModal('modalEditarHTMLEmail', 'modalEditarEmail')
                    "
                  >
                    <b-icon-code-slash /> Editar HTML
                  </button></small
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3">
                <!-- Upload aqui -->
                <input
                  id="image_mural"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageMural"
                >
                <label
                  class="custom-file-label text-left"
                  for="image_mural"
                ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <!-- /Upload aqui -->
              </div>
              <quill-editor class="teste"
                v-model="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button class="btn btn-primary" @click="editarEmail()">
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmail"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="
              showModal('modalEditarEmail', 'modalEditarHTMLEmail')
            "
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastPlataformaEmailEditar.fastEmailTemplatePlataforma.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="
              showModal('modalEditarEmail', 'modalEditarHTMLEmail')
            "
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalRestaurarEmail"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Restaurar o email para o padrão?</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRestaurarEmail')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-danger btn-sm mt-2 ml-2"
            @click.prevent="restaurarEmail()"
          >
            <i class="fa fa-ban" aria-hidden="true" /> &nbsp;Confirmar
          </button>
        </div>
      </div>
    </modal>

    <notifications group="foo" position="bottom right" />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import "cropperjs/dist/cropper.css";

export default {
  name: "HomeInternoAdminPersonalizarEmails",
  components: {
    quillEditor
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Emails
      fastPlataformaEmail: [],
      fastPlataformaEmailEditar: {    
          id_email_template: null,
          codigo_email: "",
          nome_email: "",
          descricao_email: "",
          assunto_padrao: "",
          html_email_padrao: "",
          liberado: false,
          destino_fixo: false,
          sempre_ativo: false,
          fastEmailTemplateVariavel: [
            {
              id_email_template_variavel: null,
              id_email_template: null,
              nome_variavel: "",
              propriedade: "",
              tag: ""
            },
          ],
          fastEmailTemplatePlataforma: {
            id_email_template_plataforma: null,
            id_email_template: null,
            id_plataforma: null,
            assunto: "",
            html_email: "",
            email_envio: null,
            email_copia: null,
            ativo: false
          
        },
      },
      fastPlataformaEmailRestaurar: {
        id_email: "",
        email_envio: "",
        email_copia: "",
        assunto_padrao: "",
        html_email_padrao: "",
      },
      // Editor de texto
      editorOption: {
        themme: 'snow',
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            //[{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],            
          ],
        },
      },
      modalWidth: "80%",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, "administrador")
        .then(() => {
          this.getFastPlataformaEmail();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    testeUpload(){
      console.log("Upload")
    },
    async getFastPlataformaEmail() {
      try {
        this.promiseGetFastApi("api/fast_email_template_plataforma/lista", "id_plataforma=" + this.$route.params.id_plataforma)
        .then(obj => {
          if (obj.length) {
            this.fastPlataformaEmail = obj;
          } else {
            this.fastPlataformaEmail = [];
          }
        })
        this.$store.state.fastCarregando = false;
        console.log(this.fastPlataformaEmail);
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    exibeModalEditarEmail(e) {
      this.fastPlataformaEmailEditar = e;
      this.showModal("modalEditarEmail");
    },
    async editarEmail() {
      this.$store.state.fastCarregando = true;
      let atualizaTemplateEmail = {
        id_email_template: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.id_email_template,
        id_plataforma: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.id_plataforma,
        assunto: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.assunto,
        html_email: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.html_email,
        email_envio: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.email_envio,
        email_copia: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.email_envio,
        ativo: this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.ativo
      }
      try {
        let resp = await fetch(
          settings.endApiFastEad + ("api/fast_email_template_plataforma/atualiza"),
          this.fastAjaxOptions("POST", JSON.stringify(atualizaTemplateEmail))
        )
        .then((resp) => resp.json())
        .then(response => {
          if(response.success){
            this.exibeToasty("Template de email alterado com sucesso!")
            this.hideModal('modalEditarEmail')
          } else {
            this.exibeToasty(JSON.stringify(response.description), "error")
          }
        })
        this.$store.state.fastCarregando = false
      } catch (e){
        console.log("Erro", e);
        this.$store.state.fastCarregando = false 
      }
    },
    setImageMural(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.$toast.open({
          message: "Selecione um arquivo de imagem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (file.size / 1024 > 5120) {
        this.$toast.open({
          message: "O limite de tamanho do arquivo é 5MB",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "email", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else {
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email             
                this.fastPlataformaEmailEditar.fastEmailTemplatePlataforma.html_email += `<img src="${retorno.url_image}" style="max-width: 100%">`
                this.exibeToasty("Imagem inserida com sucesso", "success");
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Recurso não suportado pelo dispositivo",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },

    exibeModalRestaurarEmail(id_email, email_envio, email_copia, assunto_padrao, html_email_padrao) {
      this.fastPlataformaEmailRestaurar.id_email = id_email;
      this.fastPlataformaEmailRestaurar.email_envio = email_envio;
      this.fastPlataformaEmailRestaurar.email_copia = email_copia;
      this.fastPlataformaEmailRestaurar.assunto_padrao = assunto_padrao;
      this.fastPlataformaEmailRestaurar.html_email_padrao = html_email_padrao;
      this.showModal("modalRestaurarEmail");
    },
    async restaurarEmail() {
      this.$store.state.fastCarregando = true;
      let atualizaTemplateEmail = {
        id_email_template: this.fastPlataformaEmailRestaurar.id_email,
        id_plataforma: this.$route.params.id_plataforma,
        assunto: this.fastPlataformaEmailRestaurar.assunto_padrao,
        html_email: this.fastPlataformaEmailRestaurar.html_email_padrao,
        email_envio: this.fastPlataformaEmailRestaurar.email_envio,
        email_copia: this.fastPlataformaEmailRestaurar.email_copia,
      }
      try {
        let resp = await fetch(
          settings.endApiFastEad + ("api/fast_email_template_plataforma/atualiza"),
          this.fastAjaxOptions("POST", JSON.stringify(atualizaTemplateEmail))
        )
        .then((resp) => resp.json())
        .then(response => {
          if(response.success){
            this.exibeToasty("Template de email restaurado ao padrão original!")
            this.getFastPlataformaEmail()
            this.hideModal('modalRestaurarEmail')
          } else {
            this.exibeToasty(JSON.stringify(response.description), "error")
          }
        })
        this.$store.state.fastCarregando = false
      } catch (e){
        console.log("Erro", e);
        this.$store.state.fastCarregando = false 
      }
    },
  },
};
</script>

<style scope>
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}
.ql-container {
  height: auto !important;
}
</style>